import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ResetPasswordReducer from "./auth/resetpwd/reducer";
import Profile from "./auth/profile/reducer";

// system emails 
import rolesReducer from "./roles/reducer";
import usersReducer from "./users/reducer";
import clientReducer from "./client/reducer";
import requestReducer from "./requests/reducer";
import notificationsReducer from "./notifications/reducer";
import leadReducer from "./leads/reducer";
import planReducer from "./plans/reducer";
import { reducer as notifications } from "react-notification-system-redux";
import twoFactorAuthReducer from "./auth/twoFactorAuth/reducer";
import todosReducer from "./todos/reducer";
import dictionaryReducer from "./dictionary/reducer";
import tradingAccountReducer from "./tradingAccounts/reducer";
import systemEmailConfigReducer from "./systemEmailConfig/reducer";
import walletReducer from "./wallet/list/reducer";
import gatewayReducer from "./gateway/reducer";
import depositReducer from "./transactions/deposit/reducer";
import withdrawalReducer from "./transactions/withdrawal/reducer";
import forexDepositReducer from "./forexTransactions/deposits/reducer";
import forexGatewayReducer from "./forexGateway/reducer";
import forexWithdrawalReducer from "./forexTransactions/withdrawals/reducer";
import companyBankAccountReducer from "./companyBankAccount/reducer";
import bankAccountReducer from "./bankAccount/reducer";
import dashboardReducer from "./dashboard/reducer";
import assetReducer from "./assests/reducer";

import teamsReducer from "./teams/reducer";
import documentsReducer from "./documents/reducer";
import logsReducer from "./logs/reducer";
import convertReducer from "./converts/reducer";
import ordersReducer from "./orders/reducer";
import feeGroupReducer from "./feeGroups/reducer";
import feeConfigurationReducer from "./feeConfiguration/reducer";
import transactionFeeGroupReducer from "./transactionFeeGroups/reducer";
import markupsReducer from "./markups/reducer";
import conversionRatesReducer from "./conversionRates/reducer";
import campaignTemplates from "./CampaignTemplates/reducer";
import emailCampaigns from "./EmailCampaigns/reducer";
import campaignUnsubscribers from "./CampaignUnsubscribers/reducer";
import systemEmailsReducer from "./systemEmail/reducer";
import discountCoupounsReducer from "./discountCoupouns/reducer";
import subscriptionsReducer from "./subscriptions/reducer";

import ibAgreementReducer from "./ibAgreements/reducer";
import reportsReducer from "./reports/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  discountCoupounsReducer,
  ForgetPassword,
  assetReducer,
  systemEmailsReducer,
  ResetPasswordReducer,
  twoFactorAuthReducer,
  Profile,
  clientReducer,
  rolesReducer,
  leadReducer,
  usersReducer,
  todosReducer,
  notifications,
  dictionaryReducer,
  planReducer,
  tradingAccountReducer,
  systemEmailConfigReducer,
  walletReducer,
  subscriptionsReducer,
  gatewayReducer,
  depositReducer,
  withdrawalReducer,
  forexDepositReducer,
  forexGatewayReducer,
  forexWithdrawalReducer,
  banks: companyBankAccountReducer,
  bankAccountReducer,
  teamsReducer,
  documentsReducer,
  logsReducer,
  ordersReducer,
  convertReducer,
  feeGroupReducer,
  requestReducer,
  feeConfigurationReducer,
  transactionFeeGroupReducer,
  markupsReducer,
  dashboardReducer,
  conversionRatesReducer,
  notificationsReducer,
  campaignTemplates,
  reportsReducer,
  emailCampaigns,
  campaignUnsubscribers,
  ibAgreements: ibAgreementReducer,
});

export default rootReducer;
