import React from "react";
// eslint-disable-next-line object-curly-newline
import "./AccountOverviewGrowth.scss";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
// import chatLogo from "assets/img/fn-chat-logo.png";
// import { ReactComponent as AccountDetailsIcon } from "assets/img/icons/account-details.svg";
// import { ReactComponent as CopyIcon } from "assets/img/icons/copy-icon.svg";
// import { ReactComponent as TradingCycleDetails } from "assets/img/icons/trading-cycle-details-icon.svg";
import { Link } from "react-router-dom";
function AccountOverviewGrowth(props) {
  const { challenge } = props;
  return (
    
    <CardWrapper className="mb-5 glass-card shadow account-growing__wrapper h-auto">
      <div className="account-growing__info-wrapper">
        <div className="account-growing__growth-wrapper">
          <p className="account-growing__growth-title">{props.t("Account Growth")}</p>
          <p className="account-growing__growth-price">{challenge?.accountGrowth}%</p>
        </div>

        <div className="account-growing__email-wrapper">
          <h3 className="account-growing__email-title">{props.t("Email Support")}</h3>
          {/* <img 
            src={chatLogo}
            className="account-growing__email-img"
            alt="Chat logo"
          /> */}
          <button className="account-growing__email-button border-0">{props.t("Contact")}</button>
          <Link to="/support@qmmfx.com" className="account-growing__email-link">support@qmmfx.com</Link>

        </div>

        {/* <div className="account-growing__email-text-wrapper">
          <p className="account-growing__email-text">
            {props.t("Today’s permitted loss will reset in")}
          </p>
          <div className="account-growing__email-timer-wrapper">
            08:53:22
          </div>
          <p className="account-growing__email-timezone">
            {props.t("Countdown Timezone: GMT+3")}
          </p>
        </div> */}
      </div>
    </CardWrapper>
  );
}
export default withTranslation()(AccountOverviewGrowth);
